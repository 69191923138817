import React from 'react'
import { Container } from "reactstrap";
import Flip from 'react-reveal/Flip';
import "../../styles/common-section.css";

const CommonSection = ({ title, banImg }) => {
    return (
      <section className="common__section mb-5" style={{ 
        background:`linear-gradient(rgb(0, 0, 0, 0.6), rgb(0, 0, 0, 0.6)),url(${banImg})`,
        backgroundPosition:'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        padding: '80px 0px'
      }}>
        <Container className="text-center">
          <Flip top>
            <h1 className="text-light">{title}</h1>
          </Flip>
        </Container>
      </section>
    );
  };

export default CommonSection