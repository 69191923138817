import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import LoaderIcon from '../../assets/img/loader.svg';

const Loading = () => {
  return (
    <div>
        <Container className="text-center">
            <Row>
                <Col>
                  <img className="LoaderAnimation" src={LoaderIcon} />    
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Loading