
import logo from "../../assets/img/logo-jpg.jpg";


const eventData = [
    {
        id:1,
        date:"Lundi 03 Octobre 2022",
        time:"08h",
        title: "Rentrée universitaire 2022-2023",
        imgUrl: logo,
        description:"",
        author:"UIM-CI",
        post: "Administration"
    },{
        id:2,
        date:"Lundi au Vendredi",
        time:"08h-16h",
        title: "Inscription & Réinscription 2022-2023",
        imgUrl: logo,
        description:"",
        author:"UIM-CI",
        post: "Administration"
    },
    {
        id:3,
        date:"Lundi 03 Octobre 2022",
        time:"08h",
        title: "Rentrée universitaire 2022-2023",
        imgUrl: logo,
        description:"",
        author:"UIM-CI",
        post: "Administration"
    },{
        id:4,
        date:"Lundi au Vendredi",
        time:"08h-16h",
        title: "Inscription & Réinscription 2022-2023",
        imgUrl: logo,
        description:"",
        author:"UIM-CI",
        post: "Administration"
    },
];

export default eventData;

