import React from "react";
import Slider from "react-slick";
import { Container } from 'reactstrap'
import LightSpeed from 'react-reveal/LightSpeed';

import '../../styles/event-section.css'

import eventData from "../../assets/data/eventData";

const EventsSection = () => {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 1000,
        swipeToSlide: true,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    
      return (
        <Container className='text-center'>
        <LightSpeed top>
            <h1  className="blogMainTitle">EVENEMENTS</h1>
            <div className="bottom"></div>
        </LightSpeed>
        
        <Slider {...settings}>
          {eventData.map((item) => (
            <div className="testimonial py-4 px-3" style={{ color:"red" }}>
              <p className="event__title">
                {item.title}
              </p>
            <p className="section__description">
              {item.description.length > 100
              ? item.description.substr(0, 100)
              : item.description}
            </p>

            <div className=" d-flex align-items-center justify-content-center gap-3">
              <span className=" d-flex align-items-center gap-1 section__time">
                <i class="ri-calendar-line"></i> {item.date}
              </span>

              <span className=" d-flex align-items-center gap-1 section__time">
                <i class="ri-time-line"></i> {item.time}
              </span>
            </div>
            

    
            <div className="mt-3 d-flex align-items-center  justify-content-center gap-4">
              <img src={item.imgUrl} alt="" className="w-25 h-25 rounded-circle" />
    
              <div>
                <h6 className="mb-0 mt-3">{item.author}</h6>
                <p className="section__description">{item.post}</p>
              </div>
            </div>
          </div>
          ))}
    
          
        </Slider>
        </Container>
      );
}

export default EventsSection