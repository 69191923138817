import React, { useEffect, useState } from 'react'
import Helmet from '../components/Helmet/Helmet'
import CommonSection from '../components/UI/CommonSection'
import Loading from '../components/UI/Loading'
import blogImg from "../assets/img/blog-img2.jpg";

const Student = () => {
 

  const [loading, setLoading] = useState(true)

  useEffect(() => {

    setTimeout(() => {
      setLoading(false)
    }, 1000);

    window.scroll(0,0)
  
  })


  if(loading){
    return (
      <Helmet title="Vie estudiantine">
        <Loading/>
      </Helmet>
    )
  } else {
    return (
      <Helmet title="Vie estudiantine">
        <CommonSection title="Vie estudiantine" banImg={blogImg} />
      </Helmet>
    )
  }
}

export default Student