



const religionFacultyData = [
    {
        id:1,
        departement: "Sciences Religieuses",
        faculty: "Sciences Religieuses",
        diplomePrepare: "Licence droit islamique",
        link: "licence-droit-islamique",

    },
    {
        id:2,
        departement: "Sciences Religieuses",
        faculty: "Sciences Religieuses",
        diplomePrepare: "Licence Philosophie Islamique",
        link: "licence-philosophie-islamique",

    },
    {
        id:3,
        departement: "Sciences Religieuses",
        faculty: "Sciences Religieuses",
        diplomePrepare: "Licence Théologie Islamique",
        link: "licence-theologie-islamique",

    },

];

export default religionFacultyData;