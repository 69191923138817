
import modalite_paiement_licence_droit from '../img/modalite_paiement.png';
import maquette_S1_SEG from '../img/maquettre_semestre1-licence-seg.png';
import maquette_S2_SEG from '../img/maquettre_semestre2-licence-seg.png';
import maquette_S3_SEG from '../img/maquettre_semestre3-licence-seg.png';
import maquette_S4_SEG from '../img/maquettre_semestre4-licence-seg.png';
import maquette_S5_Economie from '../img/maquettre_semestre5-licence-economie.png';
import maquette_S6_SEG from '../img/maquettre_semestre6-licence-economie.png';
import maquette_S5_Gestion from '../img/maquettre_semestre5-licence-gestion.png';



const economyFacultyData = [

    {
        id:1,
        departement: "Sciences Economiques & Gestions",
        faculty: "Sciences Economiques & Gestions",
        diplomePrepare: "Licence Economie",
        link: "licence-sciences-economiques",
        presentation:"Les licences en Sciences économiques et de Gestion sont des formations qui se font en trois ans. Chaque année comprend deux semestres. La formation comprend six semestres. Elle est sanctionnée par une licence en Economie ou en Gestion. La spécialisation en Economie ou en Gestion se fait pendant le 5ème et le 6ème semestres. Les deux premières années sont des formations de tronc commun. Elles  consistent à donner aux étudiants les enseignements de base en sciences économiques et de Gestion. Elles se font sur quatre semestres ( 1er, 2ème, 3ème et 4ème semestres).",
        formation_de_base: {
            1:"La formation de base ou la formation de tronc commun en Sciences économiques et de Gestion permet aux étudiants de connaitre :",
            2:"La notion de l’Economie",
            3:"L’histoire des pensées économiques ",
            4:"Les principales branches de la science économique : macro- économie et micro-économie",
            5:"Les outils d’analyse en économie et en gestion",
            6:"Les facteurs qui influent sur les décisions économiques"
        },
        condition:{
            1:"Être titulaire d’un baccalauréat de séries : G2, D et C ou de tout autre diplôme  équivalent",
            2:"Les titulaires d’un baccalauréat littéraire ou de G1 doivent subir un test en mathématiques et en statistiques ",
            3:"Une demande d’inscription adressée à la direction académique de  l’UIM-CI"
        },
        debouches:{
            0:"Les étudiants ayant validé toutes les Unités d’Enseignement seront titulaires de la Licence en Economie et seront aptes à poursuivre leur formation universitaire ou de choisir le milieu professionnel",
            1:"Analyste financier",
            2:"Assistant chargé d'études économiques et statistiques",
            3:"Chargé de clientèle banque",
            4:"Gestionnaire de clientèle"
        },
        cout:{
            frais_inscription: "200 000 FCFA",
            modalite_paiement: modalite_paiement_licence_droit
        },
        maquette:{
            semestre1:maquette_S1_SEG,
            semestre2:maquette_S2_SEG,
            semestre3:maquette_S3_SEG,
            semestre4:maquette_S4_SEG,
            semestre5:maquette_S5_Economie,
            semestre6:maquette_S6_SEG,
        }



    },
    {
        id:2,
        departement: "Sciences Economiques & Gestions",
        faculty: "Sciences Economiques & Gestions",
        diplomePrepare: "Licence Gestion",
        link: "licence-gestion",
        presentation:"Les licences en Sciences économiques et de Gestion sont des formations qui se font en trois ans. Chaque année comprend deux semestres. La formation comprend six semestres. Elle est sanctionnée par une licence en Economie ou en Gestion. La spécialisation en Economie ou en Gestion se fait pendant le 5ème et le 6ème semestres. Les deux premières années sont des formations de tronc commun. Elles  consistent à donner aux étudiants les enseignements de base en sciences économiques et de Gestion. Elles se font sur quatre semestres ( 1er, 2ème, 3ème et 4ème semestres).",
        formation_de_base: {
            1:"La formation de base ou la formation  en  Gestion permet aux étudiants de :",
            2:"Maîtriser les outils de base de la gestion : (Tenir une comptabilité, Analyser les documents de synthèse, Calculer les coûts d’une entreprise ou d’un projet ...) ",
            3:"Animer, coordonner les équipes de collaborateurs opérationnels",
            4:"Identifier et analyser les informations clés de l’environnement de l’entreprise",
            5:"Appliquer une politique marketing",
            6:"Monter un budget prévisionnel"
        },
        condition:{
            1:"Être titulaire d’un baccalauréat de séries : G2, D et C ou de tout autre diplôme  équivalent",
            2:"Les titulaires d’un baccalauréat littéraire ou de G1 doivent subir un test en mathématiques et en statistiques ",
            3:"Une demande d’inscription adressée à la direction académique de  l’UIM-CI"
        },
        debouches:{
            0:"Les étudiants ayant validé toutes les Unités d’Enseignement seront titulaires de la Licence en Gestion et seront aptes à poursuivre leur formation universitaire ou de choisir le milieu professionnel",
            1:"Assistant de gestion",
            2:"Contrôleur de gestion",
            3:"Conseiller clientèle",
            4:"Entrepreneur ..."
        },
        cout:{
            frais_inscription: "200 000 FCFA",
            modalite_paiement: modalite_paiement_licence_droit
        },
        maquette:{
            semestre1:maquette_S1_SEG,
            semestre2:maquette_S2_SEG,
            semestre3:maquette_S3_SEG,
            semestre4:maquette_S4_SEG,
            semestre5:maquette_S5_Gestion,
            semestre6:maquette_S6_SEG,
        }


    },
    {
        id:3,
        departement: "Sciences Economiques & Gestions",
        faculty: "Sciences Economiques & Gestions",
        diplomePrepare: "Master Economie",
        link: "master-sciences-economiques",


    },
    {
        id:4,
        departement: "Sciences Economiques & Gestions",
        faculty: "Sciences Economiques & Gestions",
        diplomePrepare: "Master Gestion",
        link: "master-gestion",


    },

];

export default economyFacultyData;