
import modalite_paiement_licence_droit from '../img/modalite_paiement.png';
import maquette_S1_Droit from '../img/maquettre_semestre1-licence-droit.png';
import maquette_S2_Droit from '../img/maquettre_semestre2-licence-droit.png';
import maquette_S3_Droit from '../img/maquettre_semestre3-licence-droit.png';
import maquette_S4_Droit from '../img/maquettre_semestre4-licence-droit.png';
import maquette_S5_Droit_public from '../img/maquettre_semestre5-licence-droit-public.png';
import maquette_S6_Droit_prive from '../img/maquettre_semestre6-licence-droit-prive.png';


const lawFacultyData = [
    {
        id:1,
        departement: "Sciences Juridiques",
        faculty: "Droit",
        diplomePrepare: "Licence Droit public",
        link: "licence-droit-public",
        presentation:"La licence en droit est une formation qui se fait en trois ans. Chaque année comprend deux semestres. La formation se fait sur six semestres. Elle est sanctionnée par une licence en Droit public. La spécialisation en  droit public ne se fait qu’en troisième année : pendant le 5ème et le 6ème semestres. Les deux premières années sont des formations de tronc commun. Elles  consistent à donner aux étudiants les enseignements de base en droit. Elles se font sur quatre semestres (1er, 2ème, 3ème et 4ème semestres).",
        formation_de_base: {
            1:"La formation de base ou la formation de tronc commun en droit permet aux étudiants de connaître :",
            2:"La notion de droit",
            3:"Les caractéristiques des matières en droit",
            4:"La logique du raisonnement juridique",
            5:"La méthodologie des exercices juridiques",
            6:"Les matières qui composent les principales spécialités en droit"
        },
        condition:{
            1:"Être titulaire d’un baccalauréat toute série ou de tout diplôme équivalent",
            2:"Être titulaire de capacité en droit délivrée par un établissement et autorisé à préparer de ce diplôme",
            3:"Une demande d’inscription adressée à la direction académique de  l’UIM-CI"
        },
        debouches:{
            0:"Les étudiants ayant validé toutes les Unités d’Enseignement seront titulaires de la Licence de droit public et seront aptes à poursuivre leur formation universitaire ou de choisir le milieu professionnel",
            1:"Assistant juriste en cabinet",
            2:"Chargé de programme dans les ONG humanitaires",
            3:"Juriste dans les collectivités territoriales",
            4:"Concours administratifs de la fonction publique (cycle  moyen supérieur de l’ENA), Police, Armée, Poste, etc."
        },
        cout:{
            frais_inscription: "200 000 FCFA",
            modalite_paiement: modalite_paiement_licence_droit
        },
        maquette:{
            semestre1:maquette_S1_Droit,
            semestre2:maquette_S2_Droit,
            semestre3:maquette_S3_Droit,
            semestre4:maquette_S4_Droit,
            semestre5:maquette_S5_Droit_public,
            semestre6:maquette_S6_Droit_prive,
        }

    },
    {
        id:2,
        departement: "Sciences Juridiques",
        faculty: "Droit",
        diplomePrepare: "Licence Droit privé",
        link: "licence-droit-prive",
        presentation:"La licence en droit est une formation qui se fait en trois ans. Chaque année comprend deux semestres. La formation se fait sur six semestres. Elle est sanctionnée par une licence en Droit privé. La spécialisation en  droit privé ne se fait qu’en troisième année : pendant le 5ème et le 6ème semestres. Les deux premières années sont des formations de tronc commun. Elles  consistent à donner aux étudiants les enseignements de base en droit. Elles se font sur quatre semestres (1er, 2ème, 3ème et 4ème semestres).",
        formation_de_base: {
            1:"La formation de base ou la formation de tronc commun en droit permet aux étudiants de connaître :",
            2:"La notion de droit",
            3:"Les caractéristiques des matières en droit",
            4:"La logique du raisonnement juridique",
            5:"La méthodologie des exercices juridiques",
            6:"Les matières qui composent les principales spécialités en droit"
        },
        condition:{
            1:"Être titulaire d’un baccalauréat toute série ou de tout diplôme équivalent",
            2:"Être titulaire de capacité en droit délivrée par un établissement et autorisé à préparer de ce diplôme",
            3:"Une demande d’inscription adressée à la direction académique de  l’UIM-CI"
        },
        debouches:{
            0:"Les étudiants ayant validé toutes les Unités d’Enseignement seront titulaires de la Licence de droit privé et seront aptes à poursuivre leur formation universitaire ou de choisir le milieu professionnel",
            1:"Assistant juriste en cabinet",
            2:"Chargé de programme dans les ONG humanitaires",
            3:"Juriste dans les collectivités territoriales",
            4:"Concours administratifs de la fonction publique (cycle  moyen supérieur de l’ENA), Police, Armée, Poste, etc."
        },
        cout:{
            frais_inscription: "200 000 FCFA",
            modalite_paiement: modalite_paiement_licence_droit
        },
        maquette:{
            semestre1:maquette_S1_Droit,
            semestre2:maquette_S2_Droit,
            semestre3:maquette_S3_Droit,
            semestre4:maquette_S4_Droit,
            semestre5:maquette_S5_Droit_public,
            semestre6:maquette_S6_Droit_prive,
        }

    },
    {
        id:3,
        departement: "Sciences Juridiques",
        faculty: "Droit",
        diplomePrepare: "Master Droit public",
        link: "master-droit-public",

    },
    {
        id:4,
        departement: "Sciences Juridiques",
        faculty: "Droit",
        diplomePrepare: "Master Droit privé",
        link: "master-droit-prive",

    },

];

export default lawFacultyData;