import React, { useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import "../../styles/header.css";
import  Logo  from '../../assets/img/logo.png';
import { useState } from "react";

    const navLinks = [
      {
        path: "/accueil",
        display: "Accueil",
      },
      // {
      //   path: "/presentation",
      //   display: "Présentation",
      // },
      {
        path: "/facultes",
        display: "Nos formations",
      },
      // {
      //   path: "/admission",
      //   display: "Admission",
      // },
      // {
      //   path: "/gallerie",
      //   display: "Gallerie",
      // },
      {
        path: "/etudiants",
        display: "Vie universitaire",
      },
      {
        path: "/contact",
        display: "Nos Contacts",
      }
    ];

    



const Header = () => {

  const menuRef = useRef(null);

  const toggleMenu = () => menuRef.current.classList.toggle("menu__active");

  const [navbar, setNavbar] = useState(false);


  const changeBackground = () => {
    if(window.scrollY >=230 )
    {
      setNavbar(true) 
    } else {
      setNavbar(false)
    }
  }

  window.addEventListener('scroll',changeBackground)



  return (
    <header className='header'>
      {/* ============ header top ============ */}
      <div className='header__top'>
      <Container>
        <Row>
          <Col lg="6" md="6" sm="6">
              <div className="header__top__left">
                <span className="header__top__help">
                  <i class="ri-phone-fill"></i> +225-0708299724
                </span>
              </div>
            </Col>

            <Col lg="6" md="6" sm="6">
              <div className="header__top__right d-flex align-items-center justify-content-end gap-3">
                <Link to="#" className=" d-flex align-items-center gap-1">
                  <i class="ri-user-line"></i>Mon Espace étudiant
                </Link>

              </div>
            </Col>
        </Row>
      </Container>
      </div>

      {/* =============== header middle =========== */}
      <div className="header__middle">
        <Container>
          <Row>
            <Col lg="4" md="3" sm="4">
              <div className="logo">
                <h1>
                  <Link to="/" className=" d-flex align-items-center gap-2">
                    <img src={Logo} alt="" width={70} height={90} />
                    <span>
                      UNIVERSITE INTERNATIONALE <br /> Al-MOUSTAPHA DE CÔTE D'IVOIRE
                    </span>
                  </Link>
                </h1>
              </div>
            </Col>

            <Col lg="4" md="5" sm="4">
            <br />
              <div className="header__location d-flex align-items-center gap-2">
                <span>
                <i class="ri-map-pin-line"></i>
                </span>
                <div className="header__location-content">
                  <h4>Abidjan</h4>
                  <h6>Riviera Palmeraie, SIPIM 3</h6>
                </div>
              </div>
            </Col>

            <Col lg="4" md="4" sm="4">
            <br />
              <div className="header__location d-flex align-items-center gap-2">
                <span>
                  <i class="ri-time-line"></i>
                </span>
                <div className="header__location-content">
                  <h4>Lundi au Vendredi</h4>
                  <h6>08h00 - 16h00</h6>
                </div>
              </div>
            </Col>

            {/* <Col lg="2" md="3" sm="0" className=" d-flex align-items-center justify-content-end ">
              <button className="header__btn btn ">
                <Link to="/contact">
                  <i class="ri-phone-line"></i> Request a call
                </Link>
              </button>
            </Col> */}
          </Row>
        </Container>
      </div>

      
      {/* ========== main navigation =========== */}

      <div className={navbar ? 'main__navbar fixed-top navbar__active' : 'main__navbar'}>
        <Container>
          
          <div className="navigation__wrapper d-flex align-items-center justify-content-between">
            <span className="mobile__menu">
              <i class="ri-menu-line" onClick={toggleMenu}></i>
            </span>

            <div className="navigation" ref={menuRef} onClick={toggleMenu}>
              
              <div className="menu">
              <div className="mobile__logo">
              <img src={Logo} alt="" width={70} height={90} />
              </div>
                {navLinks.map((item, index) => (
                  <NavLink
                    to={item.path}
                    className={(navClass) =>
                      navClass.isActive ? "nav__active nav__item" : "nav__item"
                    }
                    key={index}
                  >
                    {item.display}
                  </NavLink>
                ))}
              </div>
            </div>

            <div className="nav__right">
              <div className="search__box">
                <input type="text" placeholder="Rechercher" />
                <span>
                  <i class="ri-search-line"></i>
                </span>
              </div>
            </div>
          </div>
          
          <div>
              <p className="mobile__site__name"> UIM-CI</p>
          </div>
        </Container>
      </div>


      
    </header>
  )
}

export default Header