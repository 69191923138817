import React, { useEffect, useState } from 'react'
import Helmet from '../components/Helmet/Helmet'
import CommonSection from '../components/UI/CommonSection'
import Loading from '../components/UI/Loading'
import blogImg from "../assets/img/blog-img2.jpg";

const NotFound = () => {


  

  const [loading, setLoading] = useState(true)

  useEffect(() => {

    setTimeout(() => {
      setLoading(false)
    }, 1000);

    window.scroll(0,0)
  
  })


  if(loading){
    return (
      <Helmet title="Page non trouvée!!!!">
        <Loading/>
      </Helmet>
    )
  } else {
    return (
      <Helmet title="Page non trouvée!!!!">
        <CommonSection title="Page non trouvée!!!!" banImg={blogImg} />
      </Helmet>
    )
  }


}

export default NotFound