import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import pageone from '../../assets/img/page1.png';
import pagetwo from '../../assets/img/page2.png';
import pagethree from '../../assets/img/page9.png';
import Jump from 'react-reveal/Jump';
import Slide from 'react-reveal/Slide';
import Flip from 'react-reveal/Flip';


import '../../styles/welcome.css'

const Welcome = () => {
  return (
    <div className="intro-area--top">
      <Container>
        <Row>
          <Col>
            <div className="section-title text-center">
              <div className="intro-area-inner">
                <Jump>
                <h6 className="sub-title double-line">UIM-CI</h6>
                  <p className="maintitle">
                  Université internationale Al-Moustapha de Côte d’Ivoire (UIM-CI) sis à Abidjan Cocody – Riviera Palmeraie, est un établissement privé de l’enseignement supérieur agréé par arrêté N° : 035/MESRS/DGES/DESPRIV/S-DAH/KKJ du 30 janvier 2014. L’Université internationale Al-Moustapha de Côte d’Ivoire est une représentation de l’Université Internationale Al-Moustapha d’Iran en Côte d’Ivoire. L’UIM-CI est en partenariat avec l’Université LOROUGNON GUEDE DE DALOA et L’Université Félix Houphouët Boigny de Cocody.
                  </p>
                </Jump>

                
                <Container className="text-center mt-5 welcome-icon">
                  <Row>
                        <Col lg={4} md={6} sm={12}>
                            <Slide top>
                                  <img src={pageone} />
                            </Slide>
                            <Flip top>
                                  <h1 className="serviceName">Enseignement selon le système LMD </h1>
                            </Flip>
                        </Col>

                        <Col lg={4} md={6} sm={12}>
                            <Slide top>
                                  <img src={pagetwo} />
                            </Slide>
                            <Flip top>
                                  <h1 className="serviceName">Des professeurs de haut niveau et expérimentés  </h1>
                            </Flip>
                        </Col>

                        <Col lg={4} md={6} sm={12}>
                            <Slide top>
                                  <img src={pagethree} width="277px" height="177px" />
                            </Slide>
                            <Flip top>
                                  <h1 className="serviceName">Résidences universitaires pour filles et garçons  </h1>
                            </Flip>
                        </Col>
                  </Row>

                    
                 </Container>


              </div>
            </div>
          </Col>
        </Row>




      </Container>
    </div>
  )
}

export default Welcome