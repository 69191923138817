import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Helmet from '../components/Helmet/Helmet';
import CommonSection from '../components/UI/CommonSection';
import Loading from '../components/UI/Loading';
import blogImg from "../assets/img/strength-img.jpg";
import pageUnderConstruction from '../assets/img/Page-en-construction.jpg';

import lawFacultyData from "../assets/data/economyFacultyData";
import { Container,Accordion, AccordionBody, AccordionHeader,AccordionItem, Row } from 'reactstrap';


const MasterEconomie = () => {

        
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState();

    const toggle = (id) => {
        if (open === id) {
          setOpen();
        } else {
          setOpen(id);
        }
    }

    const mounted = useRef();

    useEffect(() => {

      if (!mounted.current) {
        setTimeout(() => {
          setLoading(false)
          }, 1000);

          window.scroll(0,0)
      
        mounted.current = true;
      } 

       
    })


    if(loading){
        return (
        <Helmet title="Master Economie">
            <Loading/>
        </Helmet>
        )
    } else {
        return (
          <Helmet title="Master Economie">
            <CommonSection title="Master Economie" banImg={pageUnderConstruction}  />
            
            

        </Helmet>
        )
    }
  

}

export default MasterEconomie