import React from "react";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";
import  Logo  from '../../assets/img/logo.png';

const quickLinks = [
  {
    path: "/accueil",
    display: "Accueil",
  },
  // {
  //   path: "/presentation",
  //   display: "Présentation",
  // },
  {
    path: "/facultes",
    display: "Nos formations",
  },

  // {
  //   path: "/admission",
  //   display: "Admission",
  // },
  {
    path: "/gallerie",
    display: "Gallerie",
  },,
  {
    path: "/etudiants",
    display: "Etudiants",
  },,
  {
    path: "/contact",
    display: "Contact",
  },
];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="4" sm="12">
            <div className="logo footer__logo">
              <h1>
                <Link to="/accueil" className=" d-flex align-items-center gap-2">
                  <img src={Logo} alt="" width={50} height={50} />
                  <span>
                    UIM-CI
                  </span>
                </Link>
              </h1>
            </div>
            <p className="footer__logo-content">
            Université internationale Al-Moustapha de Côte d’Ivoire (UIM-CI) sis à Abidjan Cocody – Riviera Palmeraie, est un établissement privé de l’enseignement supérieur agréé par arrêté N° : 035/MESRS/DGES/DESPRIV/S-DAH/KKJ du 30 janvier 2014.
            </p>
          </Col>

          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Liens Rapides</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col lg="3" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Nous Contacter</h5>
              <p className="office__info">Abidjan, Riviera Palmeraie, SIPIM 3</p>
              <p className="office__info">Tel: +225-0708299724 / +225-0757482493</p>

              <p className="office__info">Email: info@uim-ci.com</p>

              <p className="office__info">Ouvert: 08h00 - 16h00</p>
            </div>
          </Col>

          <Col lg="3" md="4" sm="12">
            <div className="mb-4">
              <h5 className="footer__link-title">Newsletter</h5>
              <p className="section__description">Souscrire à notre newsletter</p>
              <div className="newsletter">
                <input type="email" placeholder="Email" />
                <span>
                  <i class="ri-send-plane-line"></i>
                </span>
              </div>
            </div>
          </Col>

          <Col lg="12">
            <div className="footer__bottom">
              <p className="section__description d-flex align-items-center justify-content-center gap-1 pt-4">
                <i class="ri-copyright-line"></i>Copyright {year}, Service Informatique UIM-CI. All rights reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
