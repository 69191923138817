import React, { useEffect } from 'react'
import { useState } from 'react';
import Helmet from '../components/Helmet/Helmet'
import CommonSection from '../components/UI/CommonSection'
import Loading from "../components/UI/Loading";
import blogImg from "../assets/img/blog-img2.jpg";
import { Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../styles/contact.css'
import { Zoom } from 'react-reveal';

const Contact = () => {

  const [loading, setLoading] = useState(true)

  useEffect(() => {

    setTimeout(() => {
      setLoading(false)
    }, 1000);

    window.scroll(0,0)
  
  });

  const socialLinks = [
    {
      url: "#",
      icon: "ri-facebook-line",
    },
    {
      url: "#",
      icon: "ri-instagram-line",
    },
    {
      url: "#",
      icon: "ri-linkedin-line",
    },
    {
      url: "#",
      icon: "ri-twitter-line",
    },
  ];
  

  if(loading){
    return (
      <Helmet title="Nous Contacter">
        <Loading/>
      </Helmet>
    )
  } else {
    return (
      <Helmet title="Nous Contacter">
        <CommonSection title="Nous Contacter" banImg={blogImg} />


        <section>
          <Container>
            <Row>
              <Col lg="7" md="7">
              <Zoom left>
                <h3 className="fw-bold mb-4">Envoyez-nous un message</h3>

                <Form>
                  <FormGroup className="contact__form">
                    <Input placeholder="Votre Nom et Prénom(s)" type="text" />
                  </FormGroup>
                  <FormGroup className="contact__form">
                    <Input placeholder="Email" type="email" />
                  </FormGroup>
                  <FormGroup className="contact__form">
                    <textarea
                      rows="5"
                      placeholder="Message"
                      className="form-control"
                    ></textarea>
                  </FormGroup>

                  <button className="btn btn-warning" type="submit" disabled>
                    Envoyer Message
                  </button>
                </Form>
                </Zoom>
              </Col>

              <Col lg="5" md="5">
              <Zoom right>
                <div className="contact__info">
                  <h3 className="fw-bold">Contactez-nous</h3>
                  <p className="section__description mb-0">
                   <i className='ri-map-pin-line'></i> Abidjan, Cocody Riviera Palmeraie - SIPIM 3 face au Collège Notre Dame
                  </p>
                  <div className=" d-flex align-items-center gap-2">
                  <i className='ri-phone-line'></i><h6 className="fs-6 mb-0">Tel:</h6>
                    <p className="section__description mb-0">
                       +225-0708299724 / +225-0757482493
                    </p>
                  </div>

                  <div className=" d-flex align-items-center gap-2">
                    <i className='ri-mail-line'></i><h6 className="mb-0 fs-6">Email:</h6>
                    <p className="section__description mb-0">info@uim-ci.com</p>
                  </div>

                  <h6 className="fw-bold mt-4">Suivez-nous</h6>

                  <div className=" d-flex align-items-center gap-4 mt-3">
                    {socialLinks.map((item, index) => (
                      <Link
                        to={item.url}
                        key={index}
                        className="social__link-icon"
                      >
                        <i class={item.icon}></i>
                      </Link>
                    ))}
                  </div>
                </div>
                </Zoom>
              </Col>
            </Row>
          </Container>
      </section>



      </Helmet>
    )
  }
  


  
}

export default Contact