import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare} from '@fortawesome/free-solid-svg-icons'
import {faGlobe} from '@fortawesome/free-solid-svg-icons'
import {faLaptop} from '@fortawesome/free-solid-svg-icons'
import {faStar} from '@fortawesome/free-solid-svg-icons'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import Zoom from 'react-reveal/Zoom';
import LightSpeed from 'react-reveal/LightSpeed';
import { Col, Container, Row } from 'reactstrap';
import computerIcon from '../../assets/img/computer-icon.png';
import educationIcon from '../../assets/img/education-icon.png';
import booksIcon from '../../assets/img/books-icon.png';
import bedIcon from '../../assets/img/bed-icon.png';
import ideasIcon from '../../assets/img/ideas-icon.png';
import education2Icon from '../../assets/img/education-2-icon.png';
import '../../styles/our-strengths.css'
import Slide from 'react-reveal/Slide';
import Flip from 'react-reveal/Flip';

const OurStrengths = () => {
  return (
    <Container  fluid={true} >
        <LightSpeed top>
            <h1  className="strengthsMainTitle">NOS ATOUTS</h1>
            <div className="bottom"></div>
        </LightSpeed>
        
        <Row className="strengthsBanner p-0">
            <div className="strengthsBannerOverlay">
                <Container className='text-center'>
                <div class="intro-footer bg-base">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="single-list-inner">
                                <div class="media">
                                    <div class="media-left">
                                        <Slide top>
                                            <img src={computerIcon} alt="img" />
                                        </Slide>
                                    </div>
                                    <div class="media-body align-self-center">
                                        <Flip top>
                                            <h5>Des salles spécialisées équipées en matériels informatiques </h5>
                                        </Flip>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="single-list-inner">
                                <div class="media">
                                    <div class="media-left">
                                        <Slide top>
                                            <img src={booksIcon} alt="img" />
                                        </Slide>
                                    </div>
                                    <div class="media-body align-self-center">
                                        <Flip top>
                                            <h5>Une bibliothèque bien fournie  en ouvrage</h5>
                                        </Flip>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="single-list-inner">
                                <div class="media">
                                    <div class="media-left">
                                        <Slide top>
                                            <img src={education2Icon} alt="img" />
                                        </Slide>
                                    </div>
                                    <div class="media-body align-self-center">
                                        <Flip top>
                                            <h5>Une formation dynamique adaptée à la réalité socio-économique</h5>
                                        </Flip>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="single-list-inner">
                                <div class="media">
                                    <div class="media-left">
                                        <Slide top>
                                            <img src={ideasIcon} alt="img" />
                                        </Slide>
                                    </div>
                                    <div class="media-body align-self-center">
                                        <Flip top>
                                            <h5>Un équipement didactique adapté aux exigences d’une formation de qualité</h5>
                                        </Flip>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="single-list-inner">
                                <div class="media">
                                    <div class="media-left">
                                        <Slide top>
                                            <img src={bedIcon} alt="img" />
                                        </Slide>
                                    </div>
                                    <div class="media-body align-self-center">
                                        <Flip top>
                                            <h5>Résidences universitaires pour filles et garçons</h5>
                                        </Flip>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="single-list-inner">
                                <div class="media">
                                    <div class="media-left">
                                        <Slide top>
                                            <img src={educationIcon} alt="img" />
                                        </Slide>
                                    </div>
                                    <div class="media-body align-self-center">
                                        <Flip top>
                                            <h5>Un programme d’enseignement ouvert sur l’international</h5>
                                        </Flip>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    
                </Container>
            </div>
        </Row>
    </Container>
        
  )
}

export default OurStrengths