import React from 'react'
import { Routes, Route, Navigate  } from 'react-router-dom';
import Admission from '../pages/Admission';
import Contact from '../pages/Contact';
import Faculties from '../pages/Faculties';
import Galleries from '../pages/Galleries';
import Home from '../pages/Home';
import LicenceDroitPrive from '../pages/LicenceDroitPrive';
import LicenceDroitPublic from '../pages/LicenceDroitPublic';
import LicenceEconomie from '../pages/LicenceEconomie';
import LicenceGestion from '../pages/LicenceGestion';
import MasterDroitPrive from '../pages/MasterDroitPrive';
import MasterDroitPublic from '../pages/MasterDroitPublic';
import MasterEconomie from '../pages/MasterEconomie';
import MasterGestion from '../pages/MasterGestion';
import NotFound from '../pages/NotFound';
import Presentation from '../pages/Presentation';
import Student from '../pages/Student';


const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/accueil" />} />
      <Route path="/accueil" element={<Home />} />
      <Route path="/facultes" element={<Faculties />} />
      {/* <Route path="/admission" element={<Admission />} /> */}
      <Route path="/facultes" element={<Faculties />} />
      <Route path="/etudiants" element={<Student />} />
      {/* <Route path="/presentation" element={<Presentation />} /> */}
      {/* <Route path="/gallerie" element={<Galleries />} /> */}
      <Route path="/contact" element={<Contact />} />
      <Route path="/licence-droit-public" element={<LicenceDroitPublic />} />
      <Route path="/licence-droit-prive" element={<LicenceDroitPrive />} />
      <Route path="/master-droit-public" element={<MasterDroitPublic />} />
      <Route path="/master-droit-prive" element={<MasterDroitPrive />} />
      <Route path="/licence-sciences-economiques" element={<LicenceEconomie />} />
      <Route path="/licence-gestion" element={<LicenceGestion />} />
      <Route path="/master-sciences-economiques" element={<MasterEconomie />} />
      <Route path="/master-gestion" element={<MasterGestion />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Routers