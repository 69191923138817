import React, { useEffect, useState } from 'react'
import Helmet from '../components/Helmet/Helmet'
import HeroSlider from '../components/UI/HeroSlider'
import { Container, Row, Col } from "reactstrap";
import Welcome from '../components/UI/Welcome';
import FacultiesSection from '../components/UI/FacultiesSection';
import OurStrengths from '../components/UI/OurStrengths';
import BlogList from '../components/UI/BlogList';
import Student_Semester from '../components/UI/Student_Semester';
import EventsSection from '../components/UI/EventsSection';
import Loading from "../components/UI/Loading";

const Home = () => {

  

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000);

    window.scroll(0,0)
  
  })

  if(loading) {

    return (
      <Helmet title='Accueil'>
        <Loading/>
      </Helmet>
    )

  } else {
    return (
      <Helmet title='Accueil'>
        
        {/* ============= hero section =========== */}
        <section className="p-0 hero__slider-section">
          <HeroSlider />
  
        </section>
          <Welcome />
          
          <FacultiesSection />
          <OurStrengths />
          <BlogList />
          <EventsSection />
  
          
  
      </Helmet>
    )
  }
  


 
}

export default Home