import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import LightSpeed from 'react-reveal/LightSpeed';
import Zoom from 'react-reveal/Zoom';
import { Link } from 'react-router-dom';
import lawIcon from '../../assets/img/law-faculty.png'
import economicIcon from '../../assets/img/economic-faculty.png'
import religionIcon from '../../assets/img/religion-faculty.png'
import '../../styles/faculty.css'

import lawFacultyData from "../../assets/data/lawFacultyData";
import economyFacultyData from "../../assets/data/economyFacultyData";
import religionFacultyData from "../../assets/data/religionFacultyData";




const FacultiesSection = () => {
  return (
    <Container className='text-center'>
        <LightSpeed top>
            <h1  className="facultyMainTitle">NOS FACULTES</h1>
            <div className="bottom"></div>
        </LightSpeed>
            <Row className='facultyCardDisplay'>
                
                <Col lg={4} md={4} sm={12}>
                    <Zoom top>
                        <div className="facultyCard text-center">
                            <img className="lawIcon" src={economicIcon} /> 
                            <h2 className="facultyName">Faculté de Sciences Economiques</h2>
                            <div className='faculty__section-item' >
                                {/* <p className='d-flex justify-content-center'>
                                    <i class="ri-checkbox-circle-line"></i> Licence Droit Privé
                                </p>
                                <p className='d-flex justify-content-center'>
                                    <i class="ri-checkbox-circle-line"></i> Licence Droit Public
                                </p>
                                <p className='d-flex justify-content-center'>
                                    <i class="ri-checkbox-circle-line"></i> Master Droit Privé
                                </p>
                                <p className='d-flex justify-content-center'>
                                    <i class="ri-checkbox-circle-line"></i> Master Droit Public
                                </p> */}

                                <ul className='faculty__ul-section-item'>
                                    {economyFacultyData.map((item) => (
                                        <li>
                                            <p>
                                                <span className='d-flex justify-content-center'>
                                                    <i class="ri-checkbox-circle-line"></i>
                                                    <Link to={`/${item.link}`} className="faculty__link" >
                                                        {item.diplomePrepare}
                                                    </Link>
                                                </span>
                                            </p>
                                        </li>
                                    ))}
                                </ul>

                            </div>
                        </div>
                    </Zoom>
                </Col>
                <Col lg={4} md={4} sm={12}>
                    <Zoom top>
                        <div className="facultyCard">
                            <img className="lawIcon" src={lawIcon} /> 
                            <h2 className="facultyName">Faculté de Sciences Juridiques</h2>
                            <div className='faculty__section-item' >
                                
                            <ul className='faculty__ul-section-item'>

                                {lawFacultyData.map((item) => (
                                    <li>
                                        <p>
                                            <span className='d-flex justify-content-center'>
                                                <i class="ri-checkbox-circle-line"></i>
                                                <Link to={`/${item.link}`} className="faculty__link" >
                                                    {item.diplomePrepare}
                                                </Link>
                                            </span>
                                        </p>
                                    </li>
                                ))}
                                    
                            </ul>


                            </div>
                        </div>
                    </Zoom>
                </Col><Col lg={4} md={4} sm={12}>
                    <Zoom top>
                        <div className="facultyCard">
                            <img className="lawIcon" src={religionIcon} /> 
                            <h2 className="facultyName">Faculté de Sciences Religieuses</h2>
                            <div className='faculty__section-item' >
                                
                            <ul className='faculty__ul-section-item'>
                                    {religionFacultyData.map((item) => (
                                        <li>
                                            <p>
                                                <span className='d-flex justify-content-center'>
                                                    <i class="ri-checkbox-circle-line"></i>
                                                    <Link to={`/${item.link}`} className="faculty__link" >
                                                        {item.diplomePrepare}
                                                    </Link>
                                                </span>
                                            </p>
                                        </li>
                                    ))}
                            </ul>


                            </div>
                        </div>
                    </Zoom>
                </Col>
            </Row>

    </Container>
  )
}

export default FacultiesSection