import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Helmet from '../components/Helmet/Helmet';
import CommonSection from '../components/UI/CommonSection';
import Loading from '../components/UI/Loading';
import blogImg from "../assets/img/strength-img.jpg";

import lawFacultyData from "../assets/data/economyFacultyData";
import { Container,Accordion, AccordionBody, AccordionHeader,AccordionItem, Row } from 'reactstrap';


const LicenceGestion = () => {

        
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState();

    const toggle = (id) => {
        if (open === id) {
          setOpen();
        } else {
          setOpen(id);
        }
    }

    const mounted = useRef();

    useEffect(() => {

      if (!mounted.current) {
        setTimeout(() => {
          setLoading(false)
          }, 1000);

          window.scroll(0,0)
      
        mounted.current = true;
      } 

       
    })


    if(loading){
        return (
        <Helmet title="Licence Gestion">
            <Loading/>
        </Helmet>
        )
    } else {
        return (
          <Helmet title="Licence Gestion">
            <CommonSection title="Licence Gestion" banImg={blogImg} />
            
            {
            lawFacultyData.map((item) => {
                if(item.link === "licence-gestion"){
                   return (
                    <Container>
                        <h1>{item.diplomePrepare} </h1>
                        <h3>Présentation</h3>
                        <p>{item.presentation}</p>
                        <h3>Conditions d'accès</h3>
                        <ul>
                            <li>{item.condition[1]}</li>
                            <li>{item.condition[2]}</li>
                            <li>{item.condition[3]}</li>
                        </ul>
                        <i>Les dossiers de demande d’inscription sont analysés par une  commission de recrutement présidée par le Directeur académique.</i>
                        <h3>La formation de base en Gestion</h3>
                        <p>{item.formation_de_base[1]}</p>
                        <ul>
                          <li>{item.formation_de_base[2]}</li>
                          <li>{item.formation_de_base[3]}</li>
                          <li>{item.formation_de_base[4]}</li>
                          <li>{item.formation_de_base[5]}</li>
                          <li>{item.formation_de_base[6]}</li>
                        </ul>
                        <h3>Débouchés</h3>
                        <p>{item.debouches[0]}</p>
                        <ul>
                            <li>{item.debouches[1]}</li>
                            <li>{item.debouches[2]}</li>
                            <li>{item.debouches[3]}</li>
                            <li>{item.debouches[4]}</li>
                        </ul>
                        <h3>Coût de la formation</h3>
                        <p>Frais d'inscription : {item.cout.frais_inscription}</p>
                        <p>Frais de scolarité:</p>
                        <div>
                          <img src={item.cout.modalite_paiement} alt="" className='img-fluid center-block' style={{ display: "block",marginLeft: "auto",marginRight: "auto" }} />
                        </div>
                        <div className='bg-danger text-center text-white'>
                        <p style={{ padding:"10px",fontSize:"24px", fontWeight:"bold" }}>Possibilité de réduction de 35%</p>
                        <p style={{ fontStyle:"italic" }}>NB: Les orientés de l'Etat ne paient pas de frais de scolarité</p>
                        </div>
                        <h3>Maquette Pédagogique Licence Gestion</h3>

                        <Accordion open={open} toggle={toggle}>
                          <AccordionItem>
                            <AccordionHeader targetId="1">Semestre 1</AccordionHeader>
                            <AccordionBody accordionId="1">
                              <img src={item.maquette.semestre1} alt="" className='img-fluid' />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionHeader targetId="2">Semestre 2</AccordionHeader>
                            <AccordionBody accordionId="2">
                              <img src={item.maquette.semestre2} alt="" className='img-fluid' />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionHeader targetId="3">Semestre 3</AccordionHeader>
                            <AccordionBody accordionId="3">
                              <img src={item.maquette.semestre3} alt="" className='img-fluid' />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionHeader targetId="4">Semestre 4</AccordionHeader>
                            <AccordionBody accordionId="4">
                              <img src={item.maquette.semestre4} alt="" className='img-fluid' />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionHeader targetId="5">Semestre 5</AccordionHeader>
                            <AccordionBody accordionId="5">
                              <img src={item.maquette.semestre5} alt="" className='img-fluid' />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionHeader targetId="6">Semestre 6</AccordionHeader>
                            <AccordionBody accordionId="6">
                              <img src={item.maquette.semestre6} alt="" className='img-fluid' />
                            </AccordionBody>
                          </AccordionItem>
                        </Accordion>
                        
                    </Container>
                   )
                }
            })}

        </Helmet>
        )
    }
  

}

export default LicenceGestion